// Flyers
// Add office, hospital, nursing home, home to cont of care.
import * as React from "react";
import Footer from "../../components/Footer";
import Nav from "../../components/Nav";
import { Helmet } from "react-helmet";
import { Link, useStaticQuery, graphql } from "gatsby";
import sal from "sal.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import BookButton from "../../components/BookButton";

function Index() {
  React.useEffect(() => {
    sal();
  }, []);

  const scrollDiv = React.useRef(null);

  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { author: { ne: null } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              author
              lead_image
              published
              slug
              title
            }
          }
        }
      }
    }
  `);

  let frontmatter = [];
  query.allMarkdownRemark.edges.forEach((post) => {
    frontmatter.push(post);
  });

  const metaDesc = `New Beginnings provides Suboxone maintenance treatment to Portage and surrounding Northwest Indiana communities.`;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />({" "}
        <title>{`New Beginnings Suboxone Clinic - Addiction and substance abuse doctors in Portage, Indiana.`}</title>{" "}
        )
        <meta name="Description" content={metaDesc} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Mali:wght@600&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-X7TPGZBWQC"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-X7TPGZBWQC');
          `}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-204995244-2"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-204995244-2');
          `}
        </script>
      </Helmet>

      <Nav menuItem="index" />

      <div class="relative overflow-hidden bg-gray-50 bg-z">
        <div class="lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                <div class="lg:py-24">
                  <a
                    href="tel:12192924501"
                    class="inline-flex items-center rounded-full bg-gray-200 p-1 pr-2 text-gray-800 hover:text-indigo-600 sm:text-base lg:text-sm xl:text-base"
                  >
                    <span class="rounded-full bg-indigo-500 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                      Accepting new patients
                    </span>
                    <span class="ml-4 text-sm">Call today!</span>
                    <svg
                      class="ml-2 h-5 w-5 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                  <h1 class="mt-4 text-4xl font-bold tracking-tight text-gray-800 sm:mt-5 sm:text-5xl lg:mt-6 xl:text-6xl">
                    <span class="block">
                      WE ARE HERE FOR <span class="text-indigo-500">YOU</span>
                    </span>
                  </h1>
                  <p class="mt-3 text-base text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    If you or a loved one are suffering from drug addiction or
                    alcohol addiction, call or chat with us to schedule an
                    appointment with our outpatient Portage, IN addiction
                    treatment center today.
                  </p>

                  <p class="mt-3 text-base text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Our approach is evidence based and we can get you started on
                    drug rehab medication-assisted treatment (MAT) program
                    immediately.
                  </p>

                  <p class="mt-3 text-base text-gray-700 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    We accept Medicare, Medicaid, most insurance, and cash
                    payments.
                  </p>
                  <div class="mt-10 sm:mt-12">
                    <form action="#" class="sm:mx-auto sm:max-w-xl lg:mx-0">
                      <div class="sm:flex justify-center">
                        <div class="mt-3 sm:mt-0 sm:ml-3">
                          <a
                            href="tel:12192924501"
                            type="submit"
                            class="block w-full rounded-md bg-indigo-500 py-3 px-4 font-medium text-white shadow hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900"
                          >
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="text-white mr-2"
                            ></FontAwesomeIcon>
                            Call Us Today @ (219) 292-4501
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-center">
                  <img
                    class="rounded-lg"
                    src="https://images.unsplash.com/photo-1499568509606-4f9b771232ed?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    alt=""
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-8 mt-10 justify-center mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-0 lg:py-0 mb-20 flex">
        <div className="container flex flex-col justify-center md:flex-row mx-auto ">
          <section
            className="w-full md:w-8/12 text-gray-600 body-font relative"
            id="blog"
          >
            <h2 className="uppercase text-xl font-bold text-gray-800 sm:text-3xl sm:leading-none mb-4 mt-8 mx-2">
              Treatment Program
            </h2>
            <img
              className="hidden md:block md:float-right md:h-40"
              src="../../suboxone.png"
            />
            <ul className="mx-8">
              <li className="mx-2 pb-4 text-lg">
                <strong>INDUCTION</strong>
                <br />
                The goal of an induction is to help you transition off of opiods
                and comfortably induce (start) onto our opioid addiction
                treatment medicine called buprenorphine (Suboxone). At the first
                visit, you meet with a doctor and his treatment team, create an
                individualized treatment plan, and are induced on to the
                prescribed medication. Our staff also works to reduce the cost
                of the prescribed medication through insurance and other
                discount programs.
              </li>
              <li className="mx-2 pb-4 text-lg">
                <strong>ACUTE STABILIZATION</strong>
                <br />
                Over the first week of treatment, the doctor will help you
                stabilize and become comfortable by optimizing the daily dosage
                of medication. Our doctors stay in constant contact through the
                week, monitoring the transition, and stabilizing your body on
                the proper dosage. Once you are stable and comfortable, you and
                your doctor can finalize your customized ongoing treatment plan.
              </li>
              <li className="mx-2 pb-4 text-lg">
                <strong>MAINTENANCE</strong>
                <br />
                Medically-assisted treatment (MAT) can last from six (6) months
                to a lifetime depending on your addiction and long term goals.
                At New Beginnings, we continue to monitor and manage the
                medication on a weekly, bi-weekly, or monthly basis.
              </li>
              <li className="mx-2 pb-4 text-lg">
                <strong>TAPERING</strong>
                <br />
                When you are ready to reduce the prescribed medication, we work
                with therapists and counselors to develop a taper program that
                will ensure a comfortable, successful completion of your medical
                treatment. Our philosophy is to carefully and slowly taper your
                medication to ensure stability and success.
              </li>
            </ul>
            <div class="w-full flex justify-center">
              <a
                href="tel:12192924501"
                type="submit"
                class="block text-xl text-center rounded-md bg-indigo-500 py-4 px-8 font-medium text-white shadow hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900"
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  className="text-white mr-2"
                ></FontAwesomeIcon>
                Call Now!
              </a>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Index;

// The medical needs of elderly are diffferent because as bodies age, the need for maintaining health also changes. To remain functional and independent proper excercises and bringing balance in life is important. Preventive care including blood work, xrays, and vaccination are important for a long healthy life.
// Cardiac health, cancer screening, nutrition, mobility, mental health, dementia, adult vaccination, fall prevention,
